import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  location: any;
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  selectedValues: string,
  buttonText: string
  activeTab: number,
  selectedValuebakers: string,
  openbakers: boolean,
  categories: any[],
  itemsdishes: any[],
  mostPopularItems: any[],
  items: any[],
  popularItems: any[],
  searchQuery: string,
  openPopup: boolean,
  link: string,
  selectedVariant: string,
  selectedExtras: any[],
  customizedIngredients: any[],
  quantity: number,
  basePrice: number,
  extrasPrice: number,
  showExtras: boolean,
  showIngredients: boolean,
  showVariants: boolean,
  dishId: number,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DishesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  id = "";
  apiCategoryItemCallId: any = "";
  apiCategoryItemCallIdtwo: any = "";
  apiCategoryItemCallIdthree: any = "";
  apiMostPopularDishesCallId: any = "";
  apiPopularSpecialitiesCallId: any = "";
  private mounted: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedValues: 'ccreationDateNewest',
      buttonText: "Inprogress",
      selectedValuebakers: "createbakers",
      openbakers: false,
      categories: [
        { id: 1, name: "Cakes", image: "https://via.placeholder.com/60" },
      ],
      items: [],
      mostPopularItems: [],
      popularItems: [],
      searchQuery: "",
      itemsdishes: [],
      openPopup: false,
      link: "",
      selectedVariant: "",
      selectedExtras: [],
      customizedIngredients: [],
      quantity: 1,
      basePrice: 18.0,
      extrasPrice: 0,
      showExtras: true,
      showIngredients: true,
      showVariants: true,
      dishId: 0,
      activeTab: 0,
    };
    this.mounted = false;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.mounted = true;
    super.componentDidMount();
    this.getCategories();
    this.getCategoriestwo();
    this.getMostPopularDishes();
    this.getPopularSpecialities();
    const storedTab = localStorage.getItem("activeTab");
    if (this.mounted) {
      this.setState({
        activeTab: storedTab !== null ? parseInt(storedTab, 10) : 0,
      });
    }
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  async componentDidUpdate() {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab !== null) {
      const parsedTab = parseInt(storedTab, 10);
      if (!isNaN(parsedTab) && this.state.activeTab !== parsedTab) {
        this.setState({
          activeTab: parsedTab,
        });
      }
    }
  }
  

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId == this.apiCategoryItemCallId) {
        const categories = responseJson.data.map((item: any) => ({
          id: item.attributes.id,
          name: item.attributes.name,
          image: item.attributes.picture?.url,
        }));
        this.setState({ categories });
      }
      else if (apiRequestCallId == this.apiCategoryItemCallIdtwo) {
        const items = responseJson.data.map((item: any) => ({
          id: item.data?.id,
          bakerfirstname: item.data.attributes?.first_name,
          profilepic: item.data.attributes?.profile_pic
        }));
        this.setState({ items });
      } else if (apiRequestCallId == this.apiMostPopularDishesCallId) {
        this.handleMostPopularDishes(responseJson);
      } else if (apiRequestCallId == this.apiPopularSpecialitiesCallId) {
        this.handlePopularSpecialitiesDishes(responseJson);
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start

  getPopularSpecialities = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPopularSpecialitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.popularSpecialities}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getMostPopularDishes = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMostPopularDishesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mostPopularDishesEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getCategories = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getCategoriestwo = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallIdtwo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrltwo}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleTabChange = (event: any, newValue: any) => {
    localStorage.setItem("activeTab", newValue);
    if (newValue === 1) {
      this.props.history.push("/menu/bakers")
    }
    this.setState({ activeTab: newValue });
  }
  handleChangebakerss = (event: any) => {
    this.setState({ selectedValuebakers: event.target?.value });
  };
  toggleMenubakers = () => {
    this.setState((prevState) => ({ openbakers: !prevState.openbakers }));
  };

  goTospecialities = (id: any) => {
    localStorage.setItem("baker_id", id);
    setTimeout(() => {
      this.props.history.push("/menu/bakersinfo");
    }, 2000)
  };

  handleShare = (dishId: any) => {
    const currentUrl = window.location.href;
    this.setState({ openPopup: true, link: currentUrl })
    localStorage.setItem("dishId", dishId);
    localStorage.setItem("baker_id", dishId);
    if(window.location.pathname === "/menu/bakers"){
      this.setState({ link: `${configJSON.url}/menu/Sharebakersinfo` });
    }
  }

  handleClose = () => {
    this.setState({ openPopup: false })
  }

  handleVariantChange = (event: any) => {
    this.setState({ selectedVariant: event.target.value });
  };

  handleExtrasChange = (event: any) => {
    const { selectedExtras } = this.state;
    const { value, checked } = event.target;
    const price = parseFloat(event.target.dataset.price);

    if (checked) {
      this.setState({
        selectedExtras: [...selectedExtras, value],
        extrasPrice: this.state.extrasPrice + price,
      });
    } else {
      this.setState({
        selectedExtras: selectedExtras.filter((extra) => extra !== value),
        extrasPrice: this.state.extrasPrice - price,
      });
    }
  };

  handleCustomizeChange = (event: any) => {
    const { customizedIngredients } = this.state;
    const { value, checked } = event.target;

    if (checked) {
      this.setState({
        customizedIngredients: [...customizedIngredients, value],
      });
    } else {
      this.setState({
        customizedIngredients: customizedIngredients.filter(
          (ingredient) => ingredient !== value
        ),
      });
    }
  };

  handleQuantityChange = (type: any) => {
    this.setState((prevState) => {
      const newQuantity =
        type === "increment" ? prevState.quantity + 1 : prevState.quantity - 1;
      return {
        quantity: newQuantity > 0 ? newQuantity : 1,
      };
    });
  };

  isAnyOptionSelected = () => {
    const { selectedVariant} = this.state;
    return !!selectedVariant;
  };
  


  calculateTotal = () => {
    const { selectedExtras, quantity } = this.state;
    const basePrice = 18.0;
    const extrasTotal = selectedExtras.reduce((total, extra) => {
      const extraPrice = 2.0;
      return total + extraPrice;
    }, 0);
    const totalAmount = (basePrice + extrasTotal) * quantity;
    return totalAmount;
  };


  toggleExtrasVisibility = () => {
    this.setState((prevState) => ({
      showExtras: !prevState.showExtras,
    }));
  };

  toggleIngredientsVisibility = () => {
    this.setState((prevState) => ({
      showIngredients: !prevState.showIngredients,
    }));
  };

  toggleVariantsVisibility = () => {
    this.setState((prevState) => ({
      showVariants: !prevState.showVariants,
    }));
  };

  handleMostPopularDishes = (responseJson: any) => {
    const dishes = responseJson.data.map((item: any) => ({
      id: item.id,
      title: item.attributes.name,
      price: item.attributes.price,
      time: item.attributes.estimated_time,
      bakerFirstName: item.attributes.accounts.attributes.first_name,
      bakerLastName: item.attributes.accounts.attributes.last_name,
      rating: item.attributes.average_rating,
      dishImg: item.attributes.images?.map((img: any) => img.url),
    }));
    this.setState({ mostPopularItems: dishes })
  }

  handlePopularSpecialitiesDishes = (responseJson: any) => {
    const dishes = responseJson.data.map((item: any) => ({
      title: item.attributes.name,
      id: item.id,
      time: item.attributes.estimated_time,
      price: item.attributes.price,
      bakerLastName: item.attributes.accounts.attributes.last_name,
      bakerFirstName: item.attributes.accounts.attributes.first_name,
      dishImg: item.attributes.images?.map((img: any) => img.url),
      rating: item.attributes.average_rating,
    }));
    this.setState({ popularItems: dishes })
  }
  // Customizable Area End
}